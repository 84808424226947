import { Inter } from 'next/font/google';
import Image from 'next/image';

import SelfPNG from '@/assets/imgs/self.png';
import SelfJPG from '@/assets/imgs/self-2.jpg';

const inter = Inter({ subsets: ['latin'] });

export default function Home() {
  return (
    <div className='root flex-grow'>
      <div className='container flex h-full w-full items-center justify-center'>
        <div className='flex w-full flex-row items-center justify-center gap-0  md:gap-5 lg:gap-10'>
          <div className='flex flex-col'>
            <div className='text-title-36-bold'>Hello !</div>
            <div className='text-title-50-bold'>I&apos;m Zilong</div>
            <div className='text-title-25 mt-2'>
              Frontend Developer & Game Developer
            </div>
          </div>
          <div className='hidden md:block'>
            <Image src={SelfJPG} alt='self' width={400} />
          </div>
        </div>
      </div>
    </div>
  );
}
